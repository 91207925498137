<!--
 * @FilePath: /daren-html-source/src/components/OpenDarenDia/step1.vue
 * @Description: 开通达人挽留 弹窗1 
-->

<template>
  <el-dialog
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="baseDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left" style="opacity: 0;">
          <img :src="$utils.getPng('icon-circle-fill')" />
          <span>提示</span>
        </div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content" style="margin: 24px 0 ;">
        <p class="title">登录成功，现在开通飞猫达人立即获得收益！</p>
      </div>
      <div class="d-footer" style="padding-top: 10px">
        <fm-button @click="confirm" type="primary" size="small"
          >立即开通</fm-button
        >
        <!-- <fm-button @click="tokefu" type="info" plain size="small"
          >咨询客服</fm-button
        > -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "base",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    confirm() {
      this.$emit("confirm");
    },
    tokefu() {
       this.$emit("tokefu");
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-size: 18px;
  font-weight: 500;
  color: #2c2c2c;
}
</style>
