<template>
  <el-dialog
    :visible.sync="showChangePhoneFlag"
    :before-close="handleClose"
    :append-to-body="true"
    class="changePhoneDia"
    :close-on-click-modal="false"
  >
    <div class="diaBox2">
      <div class="dialogTop">
        <div class="left">
          <img
            class="icon"
            :src="$utils.getPng('icon-circle-fill')"
            alt=""
            srcset=""
          />
          <div class="tit1">{{ topTit }}</div>
        </div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="dialogCenter" v-show="step == 1">
        <div class="top">
          <span class="label">现有手机号 </span>
          <span>{{ this.userInfo?.pcode }} {{ this.userInfo?.phone }}</span>
        </div>
        <VerificationCode
          ref="ver"
          :showDiaFlag="showChangePhoneFlag"
          @next="next"
          @changeload="changeload"
          :error="reload"
        ></VerificationCode>
        <div
          class="getCode"
          :class="time == 60 ? 'pointer' : 'textMouse'"
          @click="getCode()"
        >
          {{
            time == 60
              ? isFirstGetCode > 0
                ? '重新获取验证码'
                : '获取验证码'
              : time + 's后重新获取'
          }}
        </div>
        <div class="bottom">
          <fm-button
            class="btn"
            :disabled="disabled"
            type="primary"
            size="medium"
            @click="next"
            >确定</fm-button
          >
        </div>
      </div>

      <div class="dialogCenter" v-show="step == 2">
        <div class="top">{{ cenTit }}</div>
        <div class="dc1" style="margin-top: 16px">
          <el-input
            v-model="value"
            placeholder="请输入手机号"
            clearable
            :class="phoneCode.length == 0?'noCode':''"
            maxlength="11"
          >
            <template slot="prepend">
              <el-select v-if="phoneCode.length > 0" popper-class="codePopper" v-model="pcode" placeholder=" " class="codeSelect suffixOne">
                <el-option-group label="选择国家或地区">
                  <el-option
                  v-for="item in phoneCode"
                  :key="item.tel_id"
                  :label="item.tel"
                  :value="item.tel">
                  <div class="valueBox">
                    <span>{{ item.name }}</span> 
                    {{ item.tel }}
                  </div>
                </el-option>
              </el-option-group>
            </el-select>
            <span class="codep" v-else>+86</span>
            </template>
          </el-input>
        </div>

        <div class="bottom">
          <fm-button
            class="btn"
            type="primary"
            size="medium"
            :disabled="value.length  == 0 || (value.length !== 11 && pcode == '+86')"
            @click="bindNewPhone()"
            >下一步</fm-button
          >
        </div>
      </div>
      <div class="dialogCenter" v-show="step == 3">
        <div class="top">
          <span class="label"
            >请输入发送至 <span class="span1">{{ pcode }} {{ value }}</span> 的
            <span style="color: #f67942">6</span> 位验证码有效期3分钟</span
          >
        </div>
        <VerificationCode
          ref="ver1"
          @next="bindphoneLast"
          @changeload="changeload1"
          :error="reload"
        ></VerificationCode>
        <div
          class="getCode"
          :class="time == 60 ? 'pointer' : 'textMouse'"
          @click="bindNewPhone()"
        >
          {{
            time == 60
              ? isFirstGetCode1 > 0
                ? '重新获取验证码'
                : '获取验证码'
              : time + 's后重新获取'
          }}
        </div>
        <div class="bottom">
          <fm-button
            class="btn"
            :disabled="disabled1"
            type="primary"
            size="medium"
            @click="bindphoneLast"
            >确定</fm-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import VerificationCode from './VerificationCode.vue'
import {
  beforePhoneSendCodeAPI,
  bindPhoneSendCode,
  beforePhoneCodeCheck,
  bindPhoneAPI,
  getAreaCode,
} from '@/utils/apiList/user'

export default {
  components: { VerificationCode },
  props: {
    userInfo: {
      type: Object,
      default: {},
    },
    showChangePhoneFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 1,
      value: '',
      topTit: '绑定手机号',
      cenTit: '请绑定手机号后再继续操作',
      msgid: '',
      disabled: true,
      time: 60,
      timerA: '',
      isFirstGetCode: 0,
      isFirstGetCode1: 0,
      pcode: '+86',
      disabled1: true,
      str1: '',
      str2: '',
      phoneCode: [],
    }
  },
  beforeDestroy() {
    this.time = 60
    this.timerA && clearInterval(this.timerA)
  },
  mounted(){
    this.getAreaCodeList()
  },
  methods: {
    getAreaCodeList(){
      getAreaCode({
        type:4
      }).then(res =>{
        if(res.status == 1){
          this.phoneCode = res.data.list
        }
      })
    },
    changeload(flag) {
      this.disabled = flag
    },
    changeload1(flag) {
      this.disabled1 = flag
    },
    reload() {},
    handleClose() {
      this.$emit('handleClose')
      this.reset()
    },
    minute: function () {
      this.time = this.time - 1
      if (this.time == 0) {
        this.time = 60
        clearInterval(this.timerA)
      }
    },
    reset() {
      this.value = ''
      this.msgid = ''
      this.isFirstGetCode = 0
      this.isFirstGetCode1 = 0
      this.time = 60
      this.timerA && clearInterval(this.timerA)
      this.pcode = '+86'
      setTimeout(() => {
        this.step = 1
        this.$refs.ver?.reset()
        this.$refs.ver1?.reset()
      }, 500)
    },

    getCode: _.debounce(
      function (captcha_verify) {
        return new Promise(resolve => {
          if (this.time != 60) return
          beforePhoneSendCodeAPI({ captcha_verify: captcha_verify })
            .then(res => {
              if (res.status == 1) {
                resolve({
                  result: true,
                  validate: true,
                })
                this.msgid = res.data.msgid
                this.$refs.ver.resetCaret()
                this.timerA = setInterval(() => {
                  this.minute()
                  this.isFirstGetCode += 1
                }, 1000)
                this.$toast(res.msg, 1)
              } else if (res.status == 90001) {
                resolve({
                  result: false,
                  validate: false,
                })
                this.$toast(res.msg, 2)
                this.$AliCode.handleOpen(this.getCode)
              } else {
                resolve({
                  result: true,
                  validate: true,
                })
                this.$toast(res.msg, 2)
              }
            })
            .catch(err => {})
        })
      },
      1000,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    bindNewPhone: _.debounce(
      function (captcha_verify) {
        return new Promise(resolve => {
          if (this.time != 60) {
            return
          }
          var reg_tel =
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          if (!reg_tel.test(this.value) && this.pcode == '+86') {
            this.$toast('请正确输入手机号', 3)
            return true
          }
          this.timerA && clearInterval(this.timerA)

          bindPhoneSendCode({
            phone: this.value,
            pcode: this.pcode,
            captcha_verify: captcha_verify || '',
          })
            .then(res => {
              if (res.status == 1) {
                resolve({
                  result: true,
                  validate: true,
                })
                this.msgid = res.data.msgid
                this.step = 3
                this.time = 60
                this.timerA = setInterval(() => {
                  this.minute()
                  this.isFirstGetCode1 += 1
                  this.$refs.ver1.resetCaret()
                }, 1000)

                this.$toast(res.msg, 1)
              } else if (res.status == 90001) {
                resolve({
                  result: false,
                  validate: false,
                })
                this.$toast(res.msg, 2)
                this.$AliCode.handleOpen(this.bindNewPhone)
              } else {
                resolve({
                  result: true,
                  validate: true,
                })
                this.$toast(res.msg, 2)
              }
            })
            .catch(err => {})
        })
      },
      1000,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    next(val) {
      if (this.disabled) return
      var yzNumber = val && val.toString().replace(/,/g, '')
      var str
      if (Array.isArray(val)) {
        this.str1 = yzNumber //存个变量存入之前的值，如果直接点击下面的btn 使用存入的值而不是鼠标对象
        str = yzNumber
      } else {
        str = this.str1
      }

      if (!this.msgid) {
        this.$toast('请先点击获取验证码', 4)
        return
      }

      beforePhoneCodeCheck({
        ucode: str,
        msgid: this.msgid,
      })
        .then(res => {
          if (res.status == 1) {
            this.$toast(res.msg, 1)
            if (this.topTit == '修改密码') {
              this.handleClose()
              this.$parent.changePassFlag = true
            } else {
              this.step = 2
              this.time = 60
              this.timerA && clearInterval(this.timerA)
              this.topTit = '修改手机号'
              this.cenTit = '请输入新手机号'
            }
          } else {
            this.$toast(res.msg, 2)
          }
        })
        .catch(err => {})
    },
    bindphoneLast(val) {
      if (this.disabled1) return

      var yzNumber = val && val.toString().replace(/,/g, '')
      var str
      if (Array.isArray(val)) {
        this.str2 = yzNumber //存个变量存入之前的值，如果直接点击下面的btn 使用存入的值而不是鼠标对象
        str = yzNumber
      } else {
        str = this.str2
      }
      if (!this.msgid) {
        this.$toast('请先点击获取验证码', 4)
        return
      }
      bindPhoneAPI({
        phone: this.value,
        pcode: this.pcode,
        ucode: str,
        msgid: this.msgid,
      })
        .then(res => {
          if (res.status == 1) {
            this.$toast(res.msg, 1)
            this.handleClose()
            this.$parent.getUser()
            if (
              this.$route.fullPath == '/llz' ||
              this.$route.fullPath == '/en-US/llz' ||
              this.$route.fullPath == '/zh-HK/llz'
            ) {
              if (this.$parent.userInfo.is_income_account == 0) {
                this.$parent.showAddpayFlag = true
              } else {
                this.$parent.userInfo.is_bind_phone = 1
                this.$parent.openDaren()
              }
            }
          } else {
            this.$toast(res.msg, 2)
          }
        })
        .catch(err => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.changePhoneDia {
  ::v-deep .el-dialog {
    min-height: 222px;
    width: 480px;
    height: auto;

    background: #ffffff;
    color: #2c2c2c;
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    .el-dialog__body {
      padding: 0;
    }
  }

  .diaBox2 {
    .dialogTop {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 20px 24px 0 24px;
      .left {
        display: flex;
        align-items: center;
      }
      .icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .tit1 {
        height: 24px;
        font-size: 16px;
        font-weight: 500;

        color: #2c2c2c;
        line-height: 24px;
      }
    }
    .dialogCenter {
      padding: 20px 24px;
      .top {
        height: 22px;
        font-size: 14px;
        font-weight: 400;

        color: #2c2c2c;
        line-height: 22px;
        .label {
          color: #8a8b92;
        }
      }
      ::v-deep .codeSelect{
        width: 80px;
        .el-input{
          width: 80px;
          .el-input__inner{
            width: 80px;
            padding-left: 12px !important;
          }
          // .el-input__suffix{
          //   right: 12px !important;
          // }
          .el-select__caret{
            color:#2c2c2c;
            font-weight: bold;
          }
        } 
      }
      ::v-deep .suffixOne{
        .el-input__suffix{
            right: 12px !important;
          }
      }
      ::v-deep .noCode{
        .el-input__inner {
          padding-left: 54px !important;
        }
      }
      .getCode {
        height: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #f67942;
        line-height: 20px;
        margin-top: 7px;
        width: 100px;
        @include textUnSelect;
      }
      .bottom {
        width: 100%;
        margin-top: 12px;
        .btn {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }
  }
  .span1 {
    color: #2c2c2c;
  }
}
.dc1 {
  margin-bottom: 24px;
  ::v-deep .el-input {
    width: 432px;
    height: 39px;
    line-height: 39px;
    display: flex;
    align-items: center;
    .el-input__inner {
      padding-left: 70px;
      height: 39px;
      line-height: 39px;
    }
    .el-input-group__prepend {
      border: none;
      box-shadow: none;
      background: transparent;
      position: absolute;
      // padding: 0px 16px 0 12px;
      line-height: 39px;
      height: 39px;
      color: #2c2c2c;
    }
  }
}
.textMouse {
  cursor: text;
}
</style>
<style lang="scss">
  .valueBox{
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        margin-right: 20px;
      }
    }
    .codePopper{
      width: 432px;
      border-radius: 8px;
    }
</style>
