import Vue from 'vue'
import VueI18n from 'vue-i18n'
//引入自定义语言配置
import zh from './zh'
import en from './en'
import zhhk from './zh-Hk'


Vue.use(VueI18n) // 全局注册国际化包  

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh', //将语言标识存入localStorage或sessionStorage中，页面刷新不会默认中文显示
  messages: {
    //简体中文语言包
    zh: {
      ...zh,
    },
    //英文语言包
    en: {
      ...en,
    },
    //繁体中文语言包
    zhhk: {
      ...zhhk,
    },
  },
  silentTranslationWarn: true, //解决vue-i18n黄色警告
  globalInjection: true, // 全局注入
  fallbackLocale: 'zh', // 指定的locale没有找到对应的资源或当前语种不存在时，默认设置当前语种为中文
})

export default i18n
